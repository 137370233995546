import {Component, OnInit} from '@angular/core';
import {MatDialogRef, MatDialog} from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';
import {CallService} from '../../services/call.service';
import {TableService} from '../../services/pager';
import {PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {CreateTdcComponent} from './create-tdc/create-tdc.component';
import {SpinnerService} from '../../services/spinner.service';

@Component({
  selector: 'app-creditcards',
  templateUrl: './creditcards.component.html',
  styleUrls: ['./creditcards.component.css']
})
export class CreditcardsComponent implements OnInit {

  displayedColumns: string[] = ['mask', 'type', 'month', 'year', 'holder', 'createdAt', 'actions'];
  dataSource: any;
  tarjetas: any = [];
  items: Array<any> = [];

  constructor(private spinner: SpinnerService, private dialog: MatDialog, private call: CallService, public auth: AuthService, private notify: NotificationService, public tableService: TableService<any>) {
  }

  ngOnInit(): void {
    this.tableService.pager.pageSize = 5;
    this.list();
  }

  list(event?: PageEvent) {
    this.spinner.open();
    this.items = [];
    let httpParams = this.call.buildRequestParams(this.tableService.sort, "m",
      {
        pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex,
        pageSize: event ? event.pageSize : this.tableService.pager.pageSize
      })

    this.call.get(this.call.HOST + '/customerInfo/list/' + this.auth.getCustomer() + '/creditcard', httpParams).subscribe(params => {
     console.log(params);
      if (params['result'] != null) {
        // Procesar las tarjetas para mostrar solo los ￃﾺltimos 4 dￃﾭgitos
        this.tarjetas = params['result'].map((tarjeta: any) => {
          if (tarjeta.mask && tarjeta.mask.length >= 4) {
            // Mostrar solo los ￃﾺltimos 4 dￃﾭgitos
            tarjeta.mask = tarjeta.mask.slice(-4);
          }
          if (tarjeta.type == "AMEX") {
            tarjeta.type = "American Express";
          }

          switch (tarjeta.month) {
            case "01" :
              tarjeta.month = "ENERO";
              break;
            case "02" :
              tarjeta.month = "FEBRERO";
              break;
            case "03" :
              tarjeta.month = "MARZO";
              break;
            case "04" :
              tarjeta.month = "ABRIL";
              break;
            case "05" :
              tarjeta.month = "MAYO";
              break;
            case "06" :
              tarjeta.month = "JUNIO";
              break;
            case "07" :
              tarjeta.month = "JULIO";
              break;
            case "08" :
              tarjeta.month = "AGOSTO";
              break;
            case "09" :
              tarjeta.month = "SEPTIEMBRE";
              break;
            case "10" :
              tarjeta.month = "OCTUBRE";
              break;
            case "11":
              tarjeta.month = "NOVIEMBRE";
              break;
            case "12" :
              tarjeta.month = "DICIEMBRE";
              break;
          }

          return tarjeta;
        });
        this.dataSource = new MatTableDataSource<any>(this.tarjetas);
        this.tableService.pager = params['pager'];
        this.spinner.close();
      } else {
        console.log("NO TDC");
        this.spinner.close();
      }

    }, err => {
      this.notify.showError(err);
      this.spinner.close();
    });
  }

  add() {

    let dialogRef: MatDialogRef<any> = this.dialog.open(CreateTdcComponent, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data: {}
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        this.list();
      })

  }


  delete(row: any) {

    let json = {
      token: row.tokenCreditCard
    }

    this.call.post(json, this.call.HOST + '/customer/' + this.auth.getCustomer() + '/creditcard/' + row.id + '/delete').subscribe(response => {
      this.notify.showSuccess("Procesada correctamente")
      //window.location.reload();
      this.list();
    }, (err) => {
      //this.spinner.hide();
      this.notify.showError("No se pudo cambiar el estado de la tarjeta, verifique que no se encuentre asociada a un plan activo");
    })


  }

}
