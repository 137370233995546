
<!-- MENU DE LA APP -->
<app-menu></app-menu>
<!-- <div id="popup" class="popup" *ngIf="showPopup">
  <div class="popup-content">
    <div class="imagepop"></div>
    <div class="textpop"><h2>Recibe $10.00 de cashback(1)
      al suscribirte al Wine Box con tu Tarjeta
      Black American Express® de BAC</h2>
      <p>Del 22 de agosto al 15 de septiembre de 2024, suscribete al Wine Box de Felipe Motta.</p>
      <b>Además, al suscribirte tendrás derecho a asistir gratuitamente a tres catas de vinos al año.</b>
      <br>
      <img class="bac" src="assets/image/BAC_Credomatic_logo.svg" alt="">
    </div>
    <span class="close-button" (click)="closePopup()">&times;</span>
   
  </div>
</div> -->
    <div class="container">
        <div class="banner">
          <div class="banner-left">
             <div class="banner-img">
             </div>
          </div>
          <div class="banner-right">
            <div class="banner-text">
              <h1>Conoce nuestro nuevo Wine Box, la suscripción mensual de <b > vinos </b> que<br> estabas esperando </h1>
             <!--p class="plan-p"> {{description}} </p-->
              <div class="box-plan">
              <div class="plan-e">
                <div class="image-logo">
                  <img alt="" src="assets/image/LogoNuevo.png">
                </div>
                <div class="col"><p >{{ plans[0]?.description }}</p></div>
                <a class="button" href="#plan-essential"><b>suscríbete</b><br> ${{ plans[0]?.feeCost_plan }} mensual</a>
              </div>
              <div class="plan-s">

                <div class="image-logo">
                  <img alt="" src="assets/image/logo_CVinos.png">
                </div>
               <!--   ó ${{ plans[2]?.feeCost_plan }} anual -->
                <div class="col"><p> {{ plans[1]?.description }}</p></div>
                <a class="plan-a button" href="#plan-signature"><b>suscríbete</b><br> ${{ plans[1]?.feeCost_plan }} bimestral </a>
                <div style="margin-top: 20px; text-align: center;" >
                  <a style="color: black;" routerLink="/subscription" [queryParams]="{option:10, plan: plans[2]?.id, oneTime:true}" > (click aquí para comprar sin renovación automática) </a>
                </div>
                
              </div>
              
              </div>
            </div>
          </div>
        </div>
        <!--Cuadro comparativo-->
        
        <div class="table-container">
          <h1>Compare nuestros planes</h1>
          <p>El precio del plan es equivalente al valor mínimo de lo vinos, que recibirás en cada entrega</p>
          <table>
            <thead>
              <tr>
                <th style="background-color: white;" class="opc"></th>
                <th class="orange" colspan="2"><h2>Wine Box</h2><!--button>JOIN</button--></th>
                <th class="redwine" colspan="2"><h2>Club de Vinos</h2><!--button>JOIN</button--></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="opc"></td>
                <td class="orange"><a href="#plan-essential">Wine Box Essentials</a></td>
                <td class="orange"><a href="#plan-essential">Wine Box Essentials 3 meses</a></td>
            
                <td class="redwine">
                  <a href="#plan-signature">Club de Vinos Bimestral</a></td>
                <td class="redwine"><a href="#plan-signature"  >Club de Vinos Anual</a></td>
              </tr>
              <tr>
                <td class="omb" colspan="5"># de Botellas por entrega</td>
              </tr>
              <tr>
                <td class="opc"># de Botellas<br> por entrega</td>
                <td>2 botellas de 750ml</td>
                <td>2 botellas de 750ml</td>
                
                <td>2 botellas de 750ml <br>o 1 botella magnum 1.5L</td>
                <td>2 botellas de 750ml <br>o 1 botella magnum 1.5L</td>
              </tr>
              <tr>
                <td class="omb" colspan="5">Frecuencia</td>
              </tr>
              <tr>
                <td class="opc">Frecuencia</td>
                <td>Todos los meses</td>
                <td>Todos los meses por 3 meses</td>
                <td>Bimestral</td>
                
                <td>Bimestral durante un año</td>
              </tr>
              <tr>
                <td class="omb" colspan="5">Precio</td>
              </tr>
              <tr>
                <td class="opc">Precio</td>
                <td>$35.00 <br>Mensual</td>
                <td>$105.00 <br>Pago único</td>
                
                <td>$50.00 <br>Bimestral</td>
                <td>$300.00 <br>Pago único</td>
              </tr>
              <tr>
                <td class="omb" colspan="5">Opciones de Planes</td>
              </tr>
              <tr>
                <td class="opc">Opciones de Planes</td>
                <td>Blancos y Rosé <br> Solo Tintos <br>Mixto</td>
                
                <td>Blancos y Rosé <br> Solo Tintos <br>Mixto</td>
                <td>Opción única</td>
                <td>Opción única</td>
              </tr>
              <tr>
                <td class="omb" colspan="5">Beneficio Adicional</td>
              </tr>
              <tr>
                <td class="opc">Beneficio Adicional</td>
                <td>3 catas gratuitas al año</td>
                <td>1 cata gratuita</td>
                <td>3 catas gratuitas al año</td>
                <td>3 catas gratuitas al año</td>
              </tr>
            </tbody>
          </table>
          <a routerLink="/terminos" target="blank_">Ver términos y condiciones.</a>
        </div>
        

        <!-- PLANES -->
        <div class="essentials" id="plan-essential">
          <div class="essentials-text">
           
          </div>
          <div class="essentials-plan">
            <div class="e-plan">
            <div class="t-plan">
              
            <!-- <h2>Essentials</h2>  -->
            <div class="image-logo">
              <img alt="" src="assets/image/LogoNuevo.png">
            </div>
             <!--    <div class="e-boton"><a routerLink="/subscription" [queryParams]="{option:2, plan: 713}" >White & Rosé</a><a routerLink="/subscription"  [queryParams]="{option:1, plan: 713}" >Only Reds</a><a routerLink="/subscription" [queryParams]="{option:3, plan: 713}">Mix</a>  -->
              
             <div class="pc-view">
               <p>Opción 1: Blancos y Rosé (recibirás 2 botellas de vino blanco o 1 botella de blanco y 1 botella de rosado) </p>
               <p>Opción 2: Solo Tintos (recibirás 2 botellas de vino tinto)</p>
               <p>Opción 3: Mixto (recibirás 1 botella de vino blanco o rosado y 1 botella de vino tinto)</p>
               </div>
               <div class="toggle-container">
                <span class="toggle-text mensual">Mensual</span>
                <mat-slide-toggle class="custom-slide-toggle" [checked]="isAnualE" (change)="toggleessencial($event)"></mat-slide-toggle>
                <span class="toggle-text anual">Pago Único Por 3 Meses</span>
              </div>
              <div *ngIf="!isAnualE" class="e-boton"><a routerLink="/subscription" [queryParams]="{option:1, plan: plans[3]?.id}" >Blancos y Rosé</a><a routerLink="/subscription"  [queryParams]="{option:2, plan: plans[0]?.id}" >Solo Tintos</a><a routerLink="/subscription" [queryParams]="{option:3, plan: plans[4]?.id}">Mixto</a>
               </div>
               <div *ngIf="isAnualE" class="e-boton"><a routerLink="/subscription" [queryParams]="{option:5, plan: plans[5]?.id, oneTime:true}" >Blancos y Rosé</a><a routerLink="/subscription"  [queryParams]="{option:6, plan: plans[5]?.id, oneTime:true}" >Solo Tintos</a><a routerLink="/subscription" [queryParams]="{option:7, plan: plans[5]?.id, oneTime:true}">Mixto</a>
               </div>
               <div class="box_new">
                <h2  *ngIf="!isAnualE" id="price">${{ plans[0]?.feeCost_plan }}</h2>
                <h2  *ngIf="isAnualE" id="price">${{ plans[5]?.feeCost_plan }}</h2>
                <img alt="" src="assets/image/LogoNuevo.png">
              </div>
            </div>
            </div>
          </div>
        </div>
        <div class="signature" id="plan-signature">
          <div class="signature-plan">
            <div class="s-plan">
            <div class="t-plan ">
              <!-- <h2>Signature</h2>  -->
              <div class="image-logo">
                <img alt="" src="assets/image/logo_CVinos.png">
              </div>
              <div  class="pc-view"><p >Nuestro plan original lanzado en el 2012, "Club de Vinos Signature", es la primera suscripción de vinos en Panamá, diseñada para paladares muy exigentes con vinos especiales para una experiencia fuera de serie. Si buscas vinos singulares, esta suscripción es para ti.</p>
             </div>
            <!-- ó e-boton   ${{ plans[2]?.feeCost_plan }} Anual  -->
           

              <div class="s-boton">
                <div class="toggle-container">
                  <span class="toggle-text mensual">Bimestral</span>
                  <mat-slide-toggle class="custom-slide-toggle" [checked]="isAnualS" (change)="togglesignature($event)"></mat-slide-toggle>
                  <span class="toggle-text anual">Pago Único Anual</span>
                </div>
                <a *ngIf="!isAnualS"  routerLink="/subscription" [queryParams]="{option:4, plan: plans[1]?.id}" >Suscribir</a> 
                <!-- <a *ngIf="isAnualS"  routerLink="/subscription" [queryParams]="{option:10, plan: plans[2]?.id, oneTime:true}" >Pago anual sin renovación</a>  -->
                <a  *ngIf="isAnualS" target="blank_" href="https://felipemotta.store/membresias-club-de-vinos-0.html" >Suscribir</a>
              </div>
              <!--p  class="description">Nuestro plan original lanzado en el 2012, "Club de Vinos Signature", es la primera suscripción de vinos en Panamá, diseñada para paladares muy exigentes con vinos especiales para una experiencia fuera de serie. Si buscas vinos singulares, esta suscripción es para ti.</p-->
              <div class="box_new">
                <h2 *ngIf="!isAnualS" id="price">${{ plans[1]?.feeCost_plan }}</h2>
                <h2 *ngIf="isAnualS"  id="price">${{ plans[2]?.feeCost_plan }}</h2>
                <img alt="" src="assets/image/logo_CVinos.png">
              </div>
            </div>
              <!-- <a routerLink="/subscription" [queryParams]="{option:5, plan: plans[2]?.id}">Anual</a> -->
            </div>
          </div>
          <div class="signature-text">
            <div class="s-text">
            <!--div class="s-text-left">
              <img alt="" src="assets/image/signature1.jpeg">
              <p>Nuestro plan original lanzado en el 2012, "Club de Vinos Signature", es la primera suscripción de vinos en Panamá, diseñada para paladares muy exigentes con vinos especiales para una experiencia fuera de serie. Si buscas vinos singulares, esta suscripción es para ti.</p>
              <h1 style="padding-top: 12vw;" >Signature</h1>
            </div-->
           
            </div>
          </div>

        </div>


        <!-- FIN DE PLANES -->

       

        
    <app-login></app-login>
    <!--end-->
    </div>
